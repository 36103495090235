export default {
  addButton: {
    id: 'add_insurance',
    defaultMessage: 'Add',
  },
  recommended: {
    id: 'insurance_cart_recommended_tag',
    defaultMessage: 'Recommended',
  },
  showMore: {
    id: 'insurance_benefits_show_more',
    defaultMessage: 'Show all benefits',
  },
  showLess: {
    id: 'insurance_benefits_show_less',
    defaultMessage: 'Show less',
  },
}
