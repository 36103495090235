<template>
  <div class="space-y-8">
    <ul class="space-y-8">
      <li
        v-for="benefit in props.benefits.slice(0, numberOfBenefitsToDisplay)"
        :key="benefit"
        class="flex items-center"
      >
        <IconCheckSmall class="shrink-0 self-start" /> {{ benefit }}
      </li>
    </ul>

    <RevLink
      v-if="withExpandableFeature"
      class="body-2-link"
      :tracking
      @click="isExpanded = !isExpanded"
    >
      {{ i18n(translations[isExpanded ? 'less' : 'more']) }}
    </RevLink>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { IconCheckSmall } from '@ds/icons/IconCheckSmall'

import translations from './Benefits.translations'

const i18n = useI18n()
const experiments = useExperiments()

const props = defineProps<{
  benefits: string[]
  tracking?: Record<string, string>
}>()

const isExpanded = ref(false)
const withExpandableFeature = computed(
  () =>
    experiments['experiment.insuranceBenefitList'] ===
    'insuranceBenefitShortList',
)

const numberOfBenefitsToDisplay = computed(() => {
  if (withExpandableFeature.value && !isExpanded.value) return 3

  return props.benefits.length
})
</script>
