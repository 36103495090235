<template>
  <span>
    <FormattedMessage :definition="translations.description">
      <template v-if="termsUrl" #terms>
        <RevLink target="_blank" :to="termsUrl">{{
          i18n(translations.terms)
        }}</RevLink>
      </template>
      <template v-if="ipidUrl" #ipid>
        <RevLink target="_blank" :to="ipidUrl">{{
          i18n(translations.ipid)
        }}</RevLink>
      </template>
      <template v-if="recapUrl" #recap>
        <RevLink target="_blank" :to="recapUrl">{{
          i18n(translations.recap)
        }}</RevLink>
      </template>
    </FormattedMessage>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { InsuranceOfferCompliancyDocument } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './Terms.translations'

const DOCUMENT_KINDS = {
  TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
  IPID: 'IPID',
  BACKMARKET_RECAP: 'BACKMARKET_RECAP',
}

const props = withDefaults(
  defineProps<{ documents: InsuranceOfferCompliancyDocument[] }>(),
  { documents: () => [] },
)

const i18n = useI18n()

const termsUrl = computed(
  () =>
    props.documents.find(
      (doc) => doc.kind === DOCUMENT_KINDS.TERMS_AND_CONDITIONS,
    )?.url,
)
const ipidUrl = computed(
  () => props.documents.find((doc) => doc.kind === DOCUMENT_KINDS.IPID)?.url,
)
const recapUrl = computed(
  () =>
    props.documents.find((doc) => doc.kind === DOCUMENT_KINDS.BACKMARKET_RECAP)
      ?.url,
)
</script>
