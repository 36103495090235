export default {
  more: {
    id: 'insurance_benefits_show_more',
    defaultMessage: 'Show all benefits',
  },
  less: {
    id: 'insurance_benefits_show_less',
    defaultMessage: 'Show less',
  },
}
