<template>
  <div
    class="border-static-default-hi text-static-default-hi rounded-md relative h-full w-full border"
  >
    <div
      :class="[
        'rounded-t-md absolute top-0 flex h-[25px] w-full justify-center',
        headerColor,
      ]"
    >
      <div
        v-if="isRecommended"
        class="text-static-success-hi caption-bold flex self-center"
      >
        <IconSparkles size="16" /> {{ i18n(translations.recommended) }}
      </div>
    </div>

    <div class="p-16">
      <div class="mt-24 flex justify-between">
        <div>
          <div class="body-1-bold">
            {{ offer.title }}
          </div>

          <InsurancePrice
            v-if="hasPrice"
            :is-monthly="offer.isMonthly"
            :price="offer.price"
          />
        </div>

        <RevButtonTiny type="submit" variant="primary" @click="addClick">
          {{ i18n(translations.addButton) }}
        </RevButtonTiny>
      </div>

      <ul class="text-static-default-low body-2 mt-12">
        <li
          v-for="benefit in benefitsToDisplay"
          :key="benefit"
          class="mt-8 flex items-center gap-4 first:mt-0"
        >
          <IconCheckInCircle
            class="text-static-success-hi mt-2 h-16 w-16 shrink-0 self-start"
          />
          {{ benefit }}
        </li>
      </ul>

      <RevLink
        v-if="props.offer.benefits.short.length > DISPLAYED_BENEFITS_BY_DEFAULT"
        class="body-2-link mt-8"
        @click="showMore"
      >
        {{
          areAllBenefitsDisplayed
            ? i18n(translations.showLess)
            : i18n(translations.showMore)
        }}
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { cartAPI, checkoutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevLink } from '@ds/components/Link'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconSparkles } from '@ds/icons/IconSparkles'

import translations from './InsuranceCard.translations'
import InsurancePrice from './components/InsurancePrice/InsurancePrice.vue'

const DISPLAYED_BENEFITS_BY_DEFAULT = 3

const props = defineProps<{
  offer:
    | cartAPI.CheckoutInsuranceOffer
    | checkoutAPI.InsurancePostPurchaseOffers
    | Record<string, never>
  trackingZone: string
}>()

const i18n = useI18n()
const tracking = useTracking()

const emit = defineEmits(['add'])

const areAllBenefitsDisplayed = ref(false)

const hasPrice = computed(
  () => !isEmpty(props.offer.price) && props.offer.price.amount !== '0.00',
)

const isRecommended = computed(() => {
  return props.offer.isRecommended
})

const headerColor = computed(() =>
  isRecommended.value ? tw`bg-static-success-mid` : tw`bg-static-default-mid`,
)

const benefitsToDisplay = computed(() => {
  if (areAllBenefitsDisplayed.value) return props.offer.benefits.short

  return props.offer.benefits.short.slice(0, DISPLAYED_BENEFITS_BY_DEFAULT)
})

const showMore = () => {
  if (!areAllBenefitsDisplayed.value)
    tracking.trackClick({
      name: 'show_benefits',
      zone: props.trackingZone,
      value: props.offer.title,
    })

  areAllBenefitsDisplayed.value = !areAllBenefitsDisplayed.value
}

const addClick = () => {
  emit('add', props.offer)
}
</script>
