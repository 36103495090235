<template>
  <RevRadioBase
    :id="id"
    checkmark-class-names="left-16 top-40 bg-transparent"
    checkmark-tick-class-names="left-22 top-[2.875rem]"
    class="flex-1"
    label=""
    :model-value="isSelected"
    :value="`${offer.id}`"
    @click="handleClick"
  >
    <label
      :class="[
        'text-static-default-hi rounded-md relative flex h-full w-full cursor-pointer border hover:bg-action-default-low-hover peer-focus-visible:outline-default-hi',
        {
          'border-static-default-hi': isSelected,
          'border-static-default-mid': !isSelected,
        },
      ]"
      :for="id"
    >
      <div
        :class="[
          'rounded-t-md absolute top-0 flex h-[25px] w-full justify-center',
          headerColor,
        ]"
      >
        <span
          v-if="isRecommended"
          class="text-static-success-hi caption-bold flex self-center"
        >
          <IconSparkles size="16" /> {{ i18n(translations.recommended) }}
        </span>
      </div>

      <div class="p-16">
        <div class="ml-28 mt-20 flex justify-between">
          <div>
            <h4 class="body-1-bold">
              {{ offer.title }}
            </h4>

            <InsurancePrice
              v-if="hasPrice"
              :is-monthly="offer.isMonthly"
              :price="offer.price"
            />
          </div>
        </div>

        <ul class="text-static-default-low body-2 mt-12">
          <li
            v-for="benefit in benefitsToDisplay"
            :key="benefit"
            class="mt-8 flex items-center gap-4 first:mt-0"
          >
            <IconCheckInCircle
              class="text-static-success-hi ml-2 mt-2 h-14 w-14 shrink-0 self-start"
            />
            <span class="ml-8">{{ benefit }}</span>
          </li>
        </ul>

        <RevLink
          v-if="
            props.offer.benefits.short.length > DISPLAYED_BENEFITS_BY_DEFAULT
          "
          class="body-2-link mt-8"
          @click="showMore"
        >
          {{
            areAllBenefitsDisplayed
              ? i18n(translations.showLess)
              : i18n(translations.showMore)
          }}
        </RevLink>
      </div>
    </label>
  </RevRadioBase>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { cartAPI, checkoutAPI } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import { RevLink } from '@ds/components/Link'
import { RevRadioBase } from '@ds/components/RadioBase'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconSparkles } from '@ds/icons/IconSparkles'

import translations from './InsuranceCard.translations'
import InsurancePrice from './components/InsurancePrice/InsurancePrice.vue'

const DISPLAYED_BENEFITS_BY_DEFAULT = 3

const props = defineProps<{
  offer:
    | cartAPI.CheckoutInsuranceOffer
    | checkoutAPI.InsurancePostPurchaseOffers
    | Record<string, never>
  listingId: string
  isSelected: boolean
  trackingZone: string
}>()

const i18n = useI18n()
const tracking = useTracking()

const emit = defineEmits(['change'])

const areAllBenefitsDisplayed = ref(false)
const id = ref(`insurance_radio_${props.listingId}_${props.offer.id}`)

const hasPrice = computed(
  () => !isEmpty(props.offer.price) && props.offer.price.amount !== '0.00',
)

const isRecommended = computed(() => {
  return props.offer.isRecommended
})

const headerColor = computed(() =>
  isRecommended.value ? tw`bg-static-success-mid` : tw`bg-static-default-hi`,
)

const benefitsToDisplay = computed(() => {
  if (areAllBenefitsDisplayed.value) return props.offer.benefits.short

  return props.offer.benefits.short.slice(0, DISPLAYED_BENEFITS_BY_DEFAULT)
})

const showMore = () => {
  if (!areAllBenefitsDisplayed.value)
    tracking.trackClick({
      name: 'show_benefits',
      zone: props.trackingZone,
      value: props.offer.title,
    })

  areAllBenefitsDisplayed.value = !areAllBenefitsDisplayed.value
}

const handleClick = () => {
  emit('change', props.offer)
}
</script>
