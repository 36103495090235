export default {
  title: {
    id: 'insurance_cart_title',
    defaultMessage:
      'Make your {deviceName, html} (almost) immortal with our coverage.',
  },
  titleRevamp: {
    id: 'insurance_cart_block_title',
    defaultMessage: '{logo, html} device protection',
  },
  recommended: {
    id: 'insurance_cart_recommended_tag',
    defaultMessage: 'Recommended',
  },
  protectedWith: {
    id: 'insurance_cart_protected_with',
    defaultMessage: 'Protected with {logo, html}',
  },
  learnMore: {
    id: 'insurance_cart_learn_more',
    defaultMessage: 'Learn more about',
  },
  learnMoreRevamp: {
    id: 'insurance_cart_learn_more_backup',
    defaultMessage: 'what BackUp gets you',
  },
}
