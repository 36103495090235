<template>
  <div class="text-static-default-low caption">
    <span class="flex items-center gap-4">
      <span class="text-static-default-hi body-1-bold">
        {{ i18n.price(price) }}
      </span>

      <template v-if="isMonthly">
        {{ i18n(translations.monthlyPayment) }}
      </template>
    </span>

    <p v-if="isMonthly" class="text-static-default-low caption">
      <span v-if="marketplace.market.countryCode === MarketCountryCode.US">
        {{ i18n(translations.cancelAnytime) }}
      </span>

      <span v-else>
        {{ i18n(translations.oneYearEnrollment, { totalPrice }) }}
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode, type Price } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import translations from './InsurancePrice.translations'

const props = defineProps<{
  price: Price
  isMonthly: boolean
}>()

const marketplace = useMarketplace()
const i18n = useI18n()

const totalPrice = computed(() => {
  return i18n.price({
    amount: (parseInt(props.price.amount, 10) * 12).toString(),
    currency: props.price.currency,
  })
})
</script>
