<template>
  <div class="space-y-24 md:space-y-24">
    <div class="text-static-default-low flex flex-col space-y-12">
      <div v-if="!withoutTitle" class="text-static-default-hi body-1">
        <FormattedMessage
          v-if="!isInsuranceRevampEnabled && !isInsuranceRevampEnabledV2"
          :definition="translations.title"
        >
          <template #deviceName>
            <span class="body-1-bold"> {{ productName }}</span>
          </template>
        </FormattedMessage>
        <FormattedMessage
          v-if="isInsuranceRevampEnabled || isInsuranceRevampEnabledV2"
          class="flex whitespace-pre"
          :definition="translations.titleRevamp"
        >
          <template #logo>
            <RevIllustration
              alt="BackUp Logo"
              class="self-end"
              :height="21"
              src="/img/insurances/backUpLogo.svg"
              :width="96"
            />
          </template>
        </FormattedMessage>
      </div>

      <div
        v-if="!isInsuranceRevampEnabled && !isInsuranceRevampEnabledV2"
        class="mb-12 flex flex-col md:flex-row"
      >
        <div
          v-for="(offer, index) in visibleOffers"
          :key="`insurance_${listingId}_${offer.id}`"
          class="relative flex-1 first:mb-12 md:first:mb-0 md:first:mr-12"
        >
          <RevTag
            v-if="shouldDisplayTag(index)"
            class="absolute -top-8 right-16 z-[1]"
            :label="i18n(translations.recommended)"
            variant="primary"
          />
          <div
            class="rounded-l-lg absolute z-[1] h-full w-[6px]"
            :class="
              index === 0
                ? 'bg-static-success-low'
                : 'bg-action-default-hi opacity-60'
            "
          />
          <RevRadio
            :id="`insurance_${listingId}_${offer.id}`"
            :class="['h-full', $style.radioLabelHeight]"
            :data-qa="`insurance-offer-${index}`"
            :model-value="selectedOffer.id === offer.id"
            :name="`insuranceOffer_${listingId}_${offer.id}`"
            :value="`${offer.id}`"
            variant="full"
            @click="() => handleOfferRadioCheck(offer)"
          >
            <template #label>
              <div>
                <span class="body-1-bold">
                  {{ offer.title }}
                </span>

                <template v-if="offer.price && offer.price.amount !== '0.00'">
                  <InsurancePrice
                    v-if="isMonthlyInsuranceEnabled"
                    :is-monthly="offer.isMonthly"
                    :price="offer.price"
                  />

                  <span v-else class="body-1 ml-4">
                    {{ `• ${i18n.price(offer.price)}` }}
                  </span>
                </template>
              </div>
            </template>
            <template #description>
              <Benefits
                :benefits="offer.benefits.short"
                class="mt-8"
                :tracking="{
                  name: 'show_benefits',
                  zone: trackingZone,
                  value: offer.title,
                }"
              />
            </template>
          </RevRadio>
        </div>
      </div>

      <div>
        <div
          v-if="isInsuranceRevampEnabled"
          class="space-y-12 lg:flex lg:flex-row lg:space-x-12 lg:space-y-0"
        >
          <InsuranceCard
            v-for="(offer, index) in visibleOffers"
            :id="`insurance_${listingId}_${offer.id}`"
            :key="`insurance_${listingId}_${offer.id}`"
            :data-qa="`insurance-offer-${index}`"
            data-test="insurance-offer"
            :listing-id="listingId"
            :offer="offer"
            :tracking-zone="trackingZone"
            @add="() => addInsurance(offer)"
          />
        </div>

        <div
          v-if="isInsuranceRevampEnabledV2"
          class="space-y-12 lg:flex lg:flex-row lg:space-x-12 lg:space-y-0"
        >
          <InsuranceCardV2
            v-for="(offer, index) in visibleOffers"
            :key="`insurance_${listingId}_${offer.id}`"
            :data-qa="`insurance-offer-${index}`"
            data-test="insurance-offer"
            :is-selected="selectedOffer.id === offer.id"
            :listing-id="listingId"
            :offer="offer"
            :tracking-zone="trackingZone"
            @change="handleOfferRadioCheck"
          />
        </div>

        <div
          class="flex flex-row items-center"
          :class="
            isInsuranceRevampEnabled || isInsuranceRevampEnabledV2
              ? 'justify-end mt-8'
              : 'justify-between'
          "
        >
          <FormattedMessage
            v-if="!isInsuranceRevampEnabled && !isInsuranceRevampEnabledV2"
            class="body-2 flex h-16 items-center"
            :definition="translations.protectedWith"
            tag="div"
          >
            <template #logo>
              <RevIllustration
                alt="BackUp logo"
                class="ml-4 mt-1"
                :height="16"
                src="/img/insurances/backUpLogo.svg"
                :width="72"
              />
            </template>
          </FormattedMessage>

          <div>
            <RevLink class="body-2-link" @click="handleInsuranceModalOpen">
              {{ i18n(translations.learnMore) }}
            </RevLink>

            <ClientOnly>
              <InsuranceModal :name="modalName" />
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>

    <div v-if="shouldDisplayTerms" class="relative">
      <RevCheckbox
        v-if="isAgreementRequired"
        :id="`compliancy-${listingId}`"
        :checked="isAgreementChecked"
        @change="handleCompliancyCheckboxChange"
      >
        <template #label>
          <Terms :documents="documents" />
        </template>
      </RevCheckbox>

      <Terms v-if="!isAgreementRequired" :documents="documents" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { cartAPI, checkoutAPI } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevRadio } from '@ds/components/Radio'
import { RevTag } from '@ds/components/Tag'

import { MODAL_NAMES } from '../../config'

import Benefits from './Benefits/Benefits.vue'
import InsuranceCard from './InsuranceCard/InsuranceCard.vue'
import InsuranceCardV2 from './InsuranceCard/InsuranceCardV2.vue'
import { InsuranceModal } from './InsuranceModal'
import InsurancePrice from './InsurancePrice/InsurancePrice.vue'
import translations from './InsurancesOptions.translations'
import { Terms } from './Terms'

type InsuranceOffer =
  | cartAPI.CheckoutInsuranceOffer
  | checkoutAPI.InsurancePostPurchaseOffers
  | Record<string, never>

const props = withDefaults(
  defineProps<{
    listingId: string
    offers: InsuranceOffer[]
    productName?: string
    showSelected: boolean
    withoutTitle?: boolean
    canBeDeselected?: boolean
    trackingZone: string
  }>(),
  {
    productName: '',
    withoutTitle: false,
    canBeDeselected: false,
  },
)

const emit = defineEmits(['update-compliancy', 'update-selected-option'])

const experiments = useExperiments()
const i18n = useI18n()
const { trackClick } = useTracking()

const selectedOffer = ref<InsuranceOffer>(
  props.showSelected
    ? Object.assign(props.offers.find((offer) => offer.selected) ?? {}, {})
    : {},
)

const modalName = `${MODAL_NAMES.CART_INSURANCE_OFFER_EXPLANATIONS}-${props.listingId}`

const isInsuranceRevampEnabled = computed(
  () => experiments['experiment.insuranceCartRevamp'] === 'newInsuranceCart',
)

const isInsuranceRevampEnabledV2 = computed(
  () =>
    experiments['experiment.insuranceCartRevampV2'] === 'newInsuranceCartV2',
)

const isMonthlyInsuranceEnabled = computed(
  () =>
    experiments['experiment.insuranceMonthlySubscription'] ===
    'insuranceMonthlyEnabled',
)

const defaultOffer = computed(
  () => props.offers.find((offer) => offer.defaultOffer) || {},
)

const visibleOffers = computed(() => {
  const offersWithoutDefault = props.offers.filter(
    (offer) => !offer.defaultOffer,
  )

  return offersWithoutDefault
})

const documents = computed(() => selectedOffer.value?.compliancy?.documents)

const isAgreementRequired = computed(
  () => selectedOffer.value?.compliancy?.isUserAgreementRequired,
)
const shouldDisplayTerms = computed(
  () =>
    !isInsuranceRevampEnabled.value &&
    !selectedOffer.value?.defaultOffer &&
    !isEmpty(selectedOffer.value?.compliancy),
)
const isAgreementChecked = computed(
  () => selectedOffer.value?.compliancy?.isUserAgreementAccepted,
)

const handleCompliancyCheckboxChange = (event: Event) => {
  const { checked } = event.target as HTMLInputElement

  if (selectedOffer.value) {
    selectedOffer.value = {
      ...selectedOffer.value,
      compliancy: {
        ...selectedOffer.value.compliancy,
        isUserAgreementAccepted: checked,
      },
    } as InsuranceOffer

    emit('update-compliancy', {
      id: props.listingId,
      insurance: selectedOffer.value,
      checked,
    })
  }
}

const handleOfferRadioCheck = (offer: InsuranceOffer) => {
  if (props.canBeDeselected && selectedOffer.value.id === offer.id) {
    selectedOffer.value = defaultOffer.value

    emit('update-selected-option', {
      id: props.listingId,
      insurance: defaultOffer.value,
    })
  } else {
    selectedOffer.value = offer

    emit('update-selected-option', {
      id: props.listingId,
      insurance: offer,
    })
  }

  trackClick({
    name: selectedOffer.value.title,
    value: selectedOffer.value.isMonthly ? 'monthly' : 'upfront',
    zone: props.trackingZone,
  })
}

const addInsurance = (offer: InsuranceOffer) => {
  selectedOffer.value = offer

  emit('update-selected-option', {
    id: props.listingId,
    insurance: offer,
  })

  trackClick({
    name: selectedOffer.value.title,
    zone: props.trackingZone,
  })
}

const handleInsuranceModalOpen = () => {
  openModal(modalName)
  trackClick({
    name: 'how_does_it_work',
    zone: props.trackingZone,
  })
}

const shouldDisplayTag = (index: number) => {
  return index === 0
}
</script>

<style module>
.radioLabelHeight > label {
  height: 100%;
}
</style>
